
import { TermsComponent } from "../components/TermsComponent/TermsComponent"

export const GeneralTerms = () => {

  return (
    <div>
        <TermsComponent />
    </div>
  )
}
