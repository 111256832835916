import { ArchiveComponent } from "../components/ArchiveComponent/ArchiveComponent"



export const Archive = () => {
  return (
    <>
      <ArchiveComponent />
    </>
  )
}
