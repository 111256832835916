
import { AboutComponent } from "../components/AboutComponent/AboutComponent"

export const About = () => {
  return (
    <>
      <AboutComponent />
    </>
  )
}
