import { EditorialBoard } from "../components/EditorialBorad/EditorialBoard"

export const EditorialBoardPage = () => {

    return (
        <div>
            <EditorialBoard />
        </div>
    )
}
