
import { NotFoundComponent } from "../components/PageNotFoundComponent/NotFoundComponent"

export const NotFound = () => {
  return (
    <>
        <NotFoundComponent />
    </>
  )
}
